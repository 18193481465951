var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { API } from "../../api/api";
import { isGraphResponse, multihop2DMParamsV2, useMultihopStrategy, } from "../../api/types/MultihopRelationship";
import { useInterruptableFetch } from "../../api/useInterruptableFetch";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_userText } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_modelFromDMParams } from "../../appState/atomic/queryState/constraintModel/fromDMParams";
import { a_activeConstraintId, a_constraintModelSolution, } from "../../appState/atomic/queryState/constraintModel/modelState";
import { a_resetQuery } from "../../appState/atomic/queryState/constraintModel/resets";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { conjunctiveToDMParams } from "../../appState/atomic/queryState/utils/conjunctiveToDMParams";
import { textToDMParams } from "../../appState/atomic/queryState/utils/textToDMParams";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { getSize } from "../../utils/generic/getSize";
import { useSuggestQuestionsFromConcepts } from "../SuggestedQuestions";
import { useStage } from "../modelBuilder/Stage";
import { dmParamsToDiagram } from "../modelBuilder/adapters/dmParamsToDiagram";
import { implyActiveConstraintId } from "./implyActiveConstraintId";
import { isValidQuestion } from "./isValidQuestion";
export function useQuestionAnalyzer() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const setActiveConstraintId = useSetAtom(a_activeConstraintId);
    const setUserText = useSetAtom(a_userText);
    const fromDMParams = useSetAtom(a_modelFromDMParams);
    const resetQuery = useSetAtom(a_resetQuery);
    const { isMultihop, discoveryStrategy } = useMultihopStrategy();
    const setModelSolution = useSetAtom(a_constraintModelSolution);
    const stage = useStage();
    const [{ modelBuilderAvailable, modelBuilderMode }] = useCurrentSession();
    const [fetch] = useInterruptableFetch();
    const suggest = useSuggestQuestionsFromConcepts();
    const supportsMultihopAnalysis = useIsFeatureSupported(Feature.QUERY_ANALYSIS_MULTIHOP);
    const supportsConjunctiveAnalsysis = useIsFeatureSupported(Feature.QUERY_ANALYSIS_CONJUNCTIVE);
    return React.useCallback((text) => {
        return new Promise((resolve, reject) => {
            if (isValidQuestion(text)) {
                resetQuery();
                const question = text.trim();
                fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                    const { dmParams, answer, evidence, references } = supportsMultihopAnalysis && isMultihop
                        ? yield discoverMultihop()
                        : supportsConjunctiveAnalsysis
                            ? yield analyzeQuery()
                            : yield simpleQuery();
                    if (modelBuilderMode && modelBuilderAvailable) {
                        const nodesAndEdges = dmParamsToDiagram(dmParams);
                        stage(nodesAndEdges, {
                            active: true,
                            layout: "H",
                            newKeys: true,
                        });
                    }
                    else {
                        fromDMParams(dmParams, undefined);
                        setActiveConstraintId(implyActiveConstraintId(dmParams));
                        setUserText(text);
                    }
                    getSize(dmParams.concepts) &&
                        suggest(Object.values(dmParams.concepts).flat());
                    setModelSolution(solution => (Object.assign(Object.assign({}, solution), { answer,
                        evidence,
                        references })));
                    resolve(true);
                    function simpleQuery() {
                        return __awaiter(this, void 0, void 0, function* () {
                            return {
                                dmParams: textToDMParams(question),
                                answer: "",
                                evidence: [],
                                references: [],
                            };
                        });
                    }
                    function analyzeQuery() {
                        return __awaiter(this, void 0, void 0, function* () {
                            const resp = yield API.analyzeQuery({ query: question, corpus_ids }, { signal, ignoreErrorHandler: true } // ignoreErrorHandler is needed because we handle errors with interruptableFetch
                            );
                            return {
                                dmParams: conjunctiveToDMParams(resp, question),
                                answer: "",
                                evidence: [],
                                references: [],
                            };
                        });
                    }
                    function discoverMultihop() {
                        return __awaiter(this, void 0, void 0, function* () {
                            const resp = yield API.discoverMultihopRelationship({
                                query: question,
                                corpus_ids,
                                strategy: discoveryStrategy,
                            }, { signal, ignoreErrorHandler: true } // ignoreErrorHandler is needed because we handle errors with interruptableFetch
                            );
                            if (isGraphResponse(resp)) {
                                return {
                                    dmParams: multihop2DMParamsV2(resp.graph),
                                    answer: resp.graph.answer,
                                    evidence: resp.graph.evidence,
                                    references: resp.graph.references,
                                };
                            }
                            throw new Error(resp.error);
                        });
                    }
                }), "Analyzing Question…");
            }
            else {
                resolve(false);
            }
        });
    }, [
        corpus_ids,
        modelBuilderAvailable,
        modelBuilderMode,
        stage,
        fromDMParams,
        setUserText,
        discoveryStrategy,
    ]);
}
