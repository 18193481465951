import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import "./animations.less";
import "./index.less";
// import {Zeno} from "./draft/zeno/Zeno"
// import {Connectors} from "./draft/Connectors"
import AuthApp from "./cora/appState/atomic/auth/AuthApp";
import { WithTheme } from "./cora/appState/context/theme/WithTheme";
import { createRoot } from "react-dom/client";
const root = document.getElementById("root");
if (root) {
    createRoot(root).render(_jsx(React.StrictMode, { children: _jsx(WithTheme, { children: _jsx(AuthApp, {}) }) }));
}
