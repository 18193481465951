var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { API } from "../../../api/api";
import { useMultihopStrategy } from "../../../api/types/MultihopRelationship";
import { useInterruptableFetch } from "../../../api/useInterruptableFetch";
import { a_modelAnswerState, a_modelResetAnswer, a_modelSolutions, } from "../../../appState/atomic/domainModel/modelState";
import { useConjunctionBindingsRequest } from "../../../appState/atomic/queryState/utils/conjunctionBindingsRequest";
import { useCurrentSession } from "../../../appState/atomic/session/session";
export function useSolutionsActions() {
    const { isMultihop } = useMultihopStrategy();
    // {}: ActionsProviderParams
    const [fetch, loading] = useInterruptableFetch();
    const setModelAnswer = useSetAtom(a_modelAnswerState);
    const resetModelAnswer = useSetAtom(a_modelResetAnswer);
    const { isSolved, solutions } = useAtomValue(a_modelSolutions);
    const [{ conjunctionBindingsSearchWidth, expandedFacetCount }] = useCurrentSession();
    const bindingsRequest = useConjunctionBindingsRequest(conjunctionBindingsSearchWidth, expandedFacetCount);
    return React.useCallback(() => {
        return {
            globalRoot: isMultihop
                ? []
                : [
                    {
                        caption: "Solutions",
                        subEntries: [
                            {
                                caption: "Explore Solutions",
                                desc: "Find concepts that satisfy the model.",
                                disabled: !bindingsRequest,
                                action() {
                                    return __awaiter(this, void 0, void 0, function* () {
                                        fetch((signal) => __awaiter(this, void 0, void 0, function* () {
                                            const response = yield API.conjunctionBindings(bindingsRequest, {
                                                signal,
                                                ignoreErrorHandler: true,
                                            });
                                            setModelAnswer(response);
                                        }), _jsxs("div", { children: ["Finding Solutions", _jsx("div", { children: "This could take some time..." }), _jsx("div", { children: "We are working so you don't have to." })] }));
                                    });
                                },
                            },
                            {
                                caption: "Clear",
                                disabled: !isSolved,
                                desc: "Clear Found Solutions.",
                                action() {
                                    resetModelAnswer();
                                },
                            },
                        ],
                    },
                ],
        };
    }, [bindingsRequest, isSolved, isMultihop]);
}
