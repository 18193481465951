import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from "antd";
import { isNumber } from "lodash";
import { useEvidenceHighlights } from "../../../appState/atomic/evidence/useEvidenceHighlights";
import { useEvidenceSelection } from "../../../appState/atomic/evidence/useEvidenceSelection";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { AnnotatedString } from "../../common/text/AnnotatedString";
import { camelify } from "../utils/utils";
import { truncateToNearestSpace } from "./EvidenceText";
import { MetadataReference } from "./RenderNiceMetadata";
import "./TextWithEvidenceReferences.less";
export function TextWithEvidenceReferences({ text, references, showReferences, showHiddenReferences, }) {
    const { evidenceSelected, selectEvidence, deselectEvidence } = useEvidenceSelection();
    const { resetEvidenceHighlight, highlightEvidence } = useEvidenceHighlights();
    const { getEvidence, getEvidenceIndex } = useEvidenceSearchUtils();
    const referenceAnnotations = references.map(r => ({
        span: r.span,
        annotation: r.evidence_id,
    }));
    return (_jsx(AnnotatedString, { text: text, annotations: referenceAnnotations, renderAnnotation: ({ text, annotations }) => {
            var _a, _b;
            if (annotations.length) {
                const index = getEvidenceIndex(annotations[0]);
                const evidence = getEvidence(annotations[0]);
                const metadata = evidence === null || evidence === void 0 ? void 0 : evidence.instances[0].metadata;
                const authors = ((_a = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "authors")) === null || _a === void 0 ? void 0 : _a.value) || [];
                const firstAuthor = authors.length > 0 ? authors[0].split(" ").slice(-1)[0] : null;
                const publicationDate = ((_b = metadata === null || metadata === void 0 ? void 0 : metadata.find(m => m.id === "publication_date")) === null || _b === void 0 ? void 0 : _b.value) || "";
                const year = publicationDate ? publicationDate.split("-")[0] : "";
                const sup = firstAuthor && year ? `${camelify(firstAuthor)} ${year}` : "*";
                const superscript = (_jsx("sup", Object.assign({ className: "font-bold cursor-pointer select-none whitespace-nowrap", onMouseEnter: () => isNumber(index) && highlightEvidence([annotations[0]]), onMouseOut: () => isNumber(index) && resetEvidenceHighlight, onClick: () => isNumber(index) &&
                        (evidenceSelected(annotations[0])
                            ? deselectEvidence(annotations)
                            : selectEvidence(annotations)) }, { children: `[${sup}]` })));
                if (!showReferences || (!showHiddenReferences && index === null))
                    return undefined;
                const evidenceDocTitle = evidence && evidence.instances.length > 0
                    ? evidence.instances[0].title
                    : undefined;
                const evidenceSnippet = evidence && evidence.text
                    ? truncateToNearestSpace(evidence.text, {
                        maxLength: 500,
                        postfix: "...",
                    })
                    : "";
                const tooltip = (_jsxs("div", Object.assign({ className: "flex flex-col items-start space-y-2" }, { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: evidenceDocTitle })), evidence && (_jsx(MetadataReference, { className: "text-sm text-gray-600", evidence: evidence })), _jsx("span", Object.assign({ className: "text-md" }, { children: evidenceSnippet }))] })));
                return (_jsx(Tooltip, Object.assign({ color: "white", placement: "bottom", overlayClassName: "EvidenceRefernceTip", title: tooltip }, { children: superscript })));
            }
            return _jsx("span", { children: text });
        } }));
}
