import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ClusteringMode } from "../../api/types/apiTypes";
import React from "react";
import { useMultihopStrategy } from "../../api/types/MultihopRelationship";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { a_constraintModelUtil, a_corpusFilterBuilder, } from "../../appState/atomic/queryState/constraintModel/ConstraintModelUtil";
import { a_addConceptToNode, a_addContextNodeId, a_clearContextNodes, a_resetConceptNodes, } from "../../appState/atomic/queryState/constraintModel/concepts";
import { a_activeConstraint, a_constraintText, a_deleteConstraint, a_isDirected, } from "../../appState/atomic/queryState/constraintModel/constraints";
import { a_activeConstraintId } from "../../appState/atomic/queryState/constraintModel/modelState";
import { a_addRelation, a_resetRelations, } from "../../appState/atomic/queryState/constraintModel/relations";
import { a_resetQuery } from "../../appState/atomic/queryState/constraintModel/resets";
import { CONTEXT_CAPTION } from "../../appState/atomic/queryState/consts/CONTEXT_CAPTION";
import { SOURCE_CAPTION } from "../../appState/atomic/queryState/consts/SOURCE_CAPTION";
import { TARGET_CAPTION } from "../../appState/atomic/queryState/consts/TARGET_CAPTION";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { ConceptSlot, ConceptSource, } from "../../appState/atomic/queryState/types/ConstraintModelState";
import { invalidCorpusIds } from "../../appState/atomic/queryState/utils/invalidCorpusIds";
import { usePopupMenuEntriesGetter } from "../../appState/atomic/queryState/utils/usePopupMenuEntriesGetter";
import { useCurrentSession } from "../../appState/atomic/session/session";
import { classes } from "../../appState/context/theme/classes";
import { CONCEPT_1_COLOR_VALS, CONCEPT_2_COLOR_VALS, CONTEXT_CONCEPT_COLOR_VALS, RELATION_COLOR_VALS, } from "../../appState/context/theme/lf";
import { uuidv4 } from "../../utils/identity/uuidv4";
import { useEffectIfDifferent } from "../../utils/lifecycle/useEffectIfDifferent";
import { useParams } from "../../utils/lifecycle/useParams";
import { useSuggestQuestionsFromConcepts } from "../SuggestedQuestions";
import { landmarkIdTag } from "../common/assist/useStartTour";
import { HeaderButton } from "../common/buttons/HeaderButton";
import { ListToggle } from "../common/buttons/ListToggle";
import { Close, ConfigHorizontal, NL, RightArrow } from "../common/icons/Icons";
import { useCurrentDomainModelDescriptor } from "../constraintFlow/useCurrentDomainModelDescriptor";
import { useDomainModelDescriptors } from "../constraintFlow/useDomainModelDescriptors";
import { ConceptsPicker, RelationsPicker } from "../picker/PickerFields";
import { QuestionInput } from "../queryAnalysis/QuestionInput";
import { useOnQuestionPost } from "../queryAnalysis/useOnQuestionPost";
import { useQuestionAnalysisHelper } from "../queryAnalysis/useQuestionAnalysisHelper";
import { DirectionPickerToggle } from "./DirectionPicker";
import { ApertureSlider } from "./aperture/ApertureSlider";
import { useShowSearchApertureModal } from "./aperture/SearchAperture";
function useCorpusFilters() {
    const corpusFilterBuilder = useAtomValue(a_corpusFilterBuilder);
    return {
        //INFO: no metadata, clauses, extra, or context, null possible
        context: corpusFilterBuilder
            .fullQuery([], [], true)
            .addQueryToFilter()
            .toCorpusFilter(),
        //INFO: no source, metadata, clauses, extra, or context, null possible
        source: corpusFilterBuilder
            .newQuery()
            .queryRelations()
            .queryTargets([], [], true)
            .addQueryToFilter()
            .toCorpusFilter(),
        //INFO: no target, metadata, clauses, extra, or context, null possible
        target: corpusFilterBuilder
            .newQuery()
            .querySources([], [], true)
            .queryRelations()
            .addQueryToFilter()
            .toCorpusFilter(),
        //INFO: no relations, metadata, clauses, extra, or context, null possible
        relations: corpusFilterBuilder
            .newQuery()
            .querySources([], [], true)
            .queryTargets([], [], true)
            .addQueryToFilter()
            .toCorpusFilter(),
    };
}
export function QueryBar() {
    const { corpus_ids } = useAtomValue(queryStateAtoms.scope);
    const activeConstraintId = useAtomValue(a_activeConstraintId);
    const constraint = useAtomValue(a_activeConstraint);
    const modelUtil = useAtomValue(a_constraintModelUtil);
    const removeConstraint = useSetAtom(a_deleteConstraint);
    const resetQuery = useSetAtom(a_resetQuery);
    const resetConceptNodes = useSetAtom(a_resetConceptNodes);
    const addConceptToNode = useSetAtom(a_addConceptToNode);
    const addContextNodeId = useSetAtom(a_addContextNodeId);
    const clearContextNodes = useSetAtom(a_clearContextNodes);
    const resetRelations = useSetAtom(a_resetRelations);
    const addRelation = useSetAtom(a_addRelation);
    const setConstraintText = useSetAtom(a_constraintText);
    const [is_directed = false, setDirected] = useAtom(a_isDirected);
    const supportsCRCSearchInput = useIsFeatureSupported(Feature.CRC_SEARCH_INPUT);
    const getPopupMenuEntries = usePopupMenuEntriesGetter();
    const sourceConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [constraint.sourceNodeId]);
    const relationClusters = modelUtil.getAllRelations(constraint);
    const targetConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, [constraint.targetNodeId]);
    const contextConcepts = modelUtil.getConceptListForNodeIds(ConceptSource.MODEL, constraint.contextNodeIds);
    const [{ conceptClustering, structuredQuery, showStructuredQuery, searchAperture }, sessionUpdater,] = useCurrentSession();
    const hasCRCInput = supportsCRCSearchInput && structuredQuery;
    const supportsSearchAperture = useIsFeatureSupported(Feature.APERTURE);
    const descriptors = useDomainModelDescriptors(corpus_ids);
    const modelSelected = useCurrentDomainModelDescriptor(descriptors, corpus_ids);
    const { isMultihop } = useMultihopStrategy();
    const analyze = useQuestionAnalysisHelper();
    useOnQuestionPost(text => {
        if (showStructuredQuery)
            sessionUpdater({ showStructuredQuery: false });
        analyze(text);
    });
    const readOnly = Boolean(modelSelected) || isMultihop;
    const disabled = invalidCorpusIds(corpus_ids) || readOnly;
    const corpusFilters = useCorpusFilters();
    const suggest = useSuggestQuestionsFromConcepts();
    const [selectedConceptClusters, updateSelectedConceptClusters] = useParams({
        concepts1: [],
        concepts2: [],
        contextConcepts: [],
    });
    useEffectIfDifferent(() => {
        const conceptClusters = selectedConceptClusters.concepts1.concat(selectedConceptClusters.concepts2);
        if (conceptClusters.length) {
            suggest(conceptClusters);
        }
    }, [], [selectedConceptClusters], true);
    const arrow = is_directed ? (_jsx("div", Object.assign({ className: "z-10 text-xs absolute border border-neutral-800 rounded-full flex items-center px-1 py-1 bg-background_header", style: { top: -5, left: -10 } }, { children: _jsx(RightArrow, { style: { fontSize: 11 } }) }))) : null;
    const nothingSelected = relationClusters.length + sourceConcepts.length + targetConcepts.length ===
        0;
    const allowContext = Boolean(sourceConcepts.length || relationClusters.length || targetConcepts.length);
    const showCRC = hasCRCInput && showStructuredQuery;
    const showSearchApertureModal = useShowSearchApertureModal();
    React.useEffect(() => {
        if (showStructuredQuery && !hasCRCInput) {
            sessionUpdater({ showStructuredQuery: false });
        }
    }, [hasCRCInput, showStructuredQuery]);
    const searchApertureSlider = supportsSearchAperture && searchAperture && (_jsx(ApertureSlider, { children: _jsx(HeaderButton, Object.assign({ disabled: nothingSelected, onMouseDown: e => {
                showSearchApertureModal();
            } }, { children: _jsx(ConfigHorizontal, { className: "text-base text-neutral-500" }) })) }));
    const pickerDisabledTooltip = modelSelected
        ? "Concept selection is disabled because a model graph is active."
        : isMultihop
            ? "Concept selection is disabled because a discovery strategy is active."
            : undefined;
    const structuredQueryInput = (_jsxs(_Fragment, { children: [_jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled, tooltip: pickerDisabledTooltip, clustering_mode: conceptClustering, corpus_filter: corpusFilters.source, title: SOURCE_CAPTION, selected: sourceConcepts, setSelected: (sources, concept_clusters) => {
                    resetConceptNodes([constraint.sourceNodeId]);
                    setConstraintText("");
                    sources.forEach(c => addConceptToNode(constraint.sourceNodeId, c));
                    sessionUpdater({ selectedSummaries: [] });
                    updateSelectedConceptClusters({ concepts1: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONCEPT_1_COLOR_VALS, tagColorBase: "notification_blue", argument_name: is_directed ? "subject" : undefined, popupMenuEntries: getPopupMenuEntries(ConceptSlot.SOURCE), landmarkId: "concept1Picker", entityType: "PrimaryConcept" }), _jsx("div", Object.assign({ className: "relative" }, { children: arrow })), _jsx(RelationsPicker, { corpus_ids: corpus_ids, concepts1: sourceConcepts, concepts2: targetConcepts, disabled: disabled, tooltip: pickerDisabledTooltip, clustering_mode: ClusteringMode.NONE, corpus_filter: corpusFilters.relations, title: "Relation", selected: relationClusters, setSelected: relations => {
                    resetRelations();
                    setConstraintText("");
                    relations.forEach(r => addRelation(r));
                    sessionUpdater({ selectedSummaries: [] });
                }, placeholder: "select relation(s)\u2026", tagColorVals: RELATION_COLOR_VALS, tagColorBase: "alert_green", landmarkId: "relationPicker" }), _jsx("div", Object.assign({ className: "relative" }, { children: arrow })), _jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled, tooltip: pickerDisabledTooltip, clustering_mode: conceptClustering, corpus_filter: corpusFilters.target, title: TARGET_CAPTION, selected: targetConcepts, setSelected: (targets, concept_clusters) => {
                    resetConceptNodes([constraint.targetNodeId]);
                    setConstraintText("");
                    targets.forEach(c => addConceptToNode(constraint.targetNodeId, c));
                    sessionUpdater({ selectedSummaries: [] });
                    updateSelectedConceptClusters({ concepts2: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONCEPT_2_COLOR_VALS, tagColorBase: "alert_yellow", argument_name: is_directed ? "object" : undefined, popupMenuEntries: getPopupMenuEntries(ConceptSlot.TARGET), landmarkId: "concept2Picker", entityType: "LinkedConcept", placement: "bottomRight" }), _jsx(ConceptsPicker, { corpus_ids: corpus_ids, disabled: disabled || !allowContext, clustering_mode: conceptClustering, corpus_filter: corpusFilters.context, title: CONTEXT_CAPTION, tooltip: pickerDisabledTooltip
                    ? pickerDisabledTooltip
                    : allowContext
                        ? "Find anywhere in the document"
                        : "You must select concepts or relations before selecting context.", selected: contextConcepts, setSelected: (contextConcepts, concept_clusters) => {
                    resetConceptNodes(constraint.contextNodeIds);
                    clearContextNodes();
                    setConstraintText("");
                    contextConcepts.forEach(c => {
                        // context concepts get their own node
                        const nodeId = uuidv4();
                        addContextNodeId(nodeId);
                        addConceptToNode(nodeId, c);
                    });
                    sessionUpdater({ selectedSummaries: [] });
                    updateSelectedConceptClusters({ contextConcepts: concept_clusters });
                }, placeholder: "select concept(s)\u2026", tagColorVals: CONTEXT_CONCEPT_COLOR_VALS, tagColorBase: "accent", argument_name: undefined, popupMenuEntries: getPopupMenuEntries(ConceptSlot.CONTEXT), landmarkId: "contextPicker", entityType: "ContextConcept", placement: "bottomRight" }), _jsx(DirectionPickerToggle, { disabled: disabled, is_directed: is_directed, setDirected: is_directed => {
                    setDirected(is_directed);
                }, className: "text-text_header" }), " ", _jsx(HeaderButton, Object.assign({ className: showCRC ? "mt-2" : "", tooltip: "Clear current query", onMouseDown: () => {
                    resetQuery();
                    activeConstraintId && removeConstraint(activeConstraintId);
                    sessionUpdater({ selectedSummaries: [] });
                }, disabled: disabled || modelUtil.isConstraintEmpty(constraint) }, { children: _jsx(Close, { className: "text-lg text-neutral-500" }) }))] }));
    const queryInput = showCRC ? (structuredQueryInput) : (_jsx(QuestionInput, { analyze: analyze }));
    return (_jsx("div", Object.assign({ className: "flex flex-col space-y-2 items-stretch w-full" }, landmarkIdTag("tour-crcOverview"), { children: _jsxs("div", Object.assign({ className: "flex flex-row space-x-2 items-center" }, { children: [hasCRCInput && (_jsx("div", Object.assign({ className: classes("flex self-stretch items-center text-tiny space-x-1") }, { children: _jsx(ListToggle, { style: { padding: "1px" }, toggleEntries: [
                            {
                                key: "nl",
                                icon: _jsx(NL, { className: "text-md" }),
                                onClick: () => {
                                    sessionUpdater({
                                        showStructuredQuery: !showStructuredQuery,
                                    });
                                },
                                tooltip: "Natural Language Query",
                            },
                            {
                                key: "structured",
                                icon: _jsx("span", Object.assign({ className: "text-xs font-bold" }, { children: "CRC" })),
                                onClick: () => {
                                    sessionUpdater({
                                        showStructuredQuery: !showStructuredQuery,
                                    });
                                },
                                tooltip: "Structured Query",
                            },
                        ], activeToggle: showStructuredQuery ? "structured" : "nl", direction: "row" }) }))), _jsxs("div", Object.assign({ className: "flex-1 flex items-center space-x-1" }, { children: [queryInput, searchApertureSlider] }))] })) })));
}
