import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio } from "antd";
import { useSetAtom } from "jotai";
import React from "react";
import { useMultihopStrategy } from "../../api/types/MultihopRelationship";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useGlobalInfo } from "../../appState/atomic/globalInfo";
import { queryStateAtoms } from "../../appState/atomic/queryState/queryStateAtoms";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { Down, Robot } from "../common/icons/Icons";
import { DropDownMenu } from "../common/popups/DropDownMenu";
import { Tooltip } from "../common/popups/Tooltip";
//takes a string and returns a string with the first letters capitalized and replaces underscores with spaces
function prettyText(text) {
    return text
        .toLowerCase()
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
export function MultiHopModelPicker() {
    const { multihopDiscoveryStrategies } = useGlobalInfo();
    const [{ showMultihopStrategySelection }] = useUserConfig();
    const supportsMultihopAnalysis = useIsFeatureSupported(Feature.QUERY_ANALYSIS_MULTIHOP);
    const { isMultihop, discoveryStrategy } = useMultihopStrategy();
    const setStrategy = useSetAtom(queryStateAtoms.multihopConfig);
    const onSelect = (strategy) => {
        setStrategy({ discoveryStrategy: strategy });
    };
    React.useEffect(() => {
        if (!supportsMultihopAnalysis) {
            setStrategy({ discoveryStrategy: "NONE" });
        }
    }, [supportsMultihopAnalysis]);
    if (!supportsMultihopAnalysis ||
        (!showMultihopStrategySelection && !isMultihop))
        return null;
    return (_jsx(DropDownMenu, Object.assign({ title: "Discovery Strategy", hasClose: true, entries: () => {
            return multihopDiscoveryStrategies.sort().map(c => ({
                caption: prettyText(c),
                icon: () => _jsx(Radio, { checked: c === discoveryStrategy }),
                action() {
                    if (discoveryStrategy !== c) {
                        onSelect(c);
                    }
                },
            }));
        } }, { children: _jsx(Tooltip, Object.assign({ content: "Multihop Discovery Strategy" }, { children: _jsxs("div", Object.assign({ className: "flex items-center space-x-1 px-1" }, { children: [_jsx(Robot, { className: "text-base mr-1" }), prettyText(discoveryStrategy), _jsx(Down, { className: "text-xs" })] })) })) })));
}
