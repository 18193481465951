var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Url from "url";
import { Tooltip } from "../../components/common/popups/Tooltip";
import { AnnotatedString, findTextSpans, } from "../../components/common/text/AnnotatedString";
import { setLoading } from "../../components/common/transitions/Loading";
import { showDocViewer } from "../../components/modals/docViewer/DocViewer";
import { limitLength } from "../../utils/text/limitLength";
import { API } from "../api";
export function getCaption(source) {
    if (source.title)
        return source.title;
    if (source.external_url)
        return Url.parse(source.external_url).hostname || undefined;
    return undefined;
}
export function getDocumentUrl(docId) {
    return `/api/v1/corpora/document?id=${docId}`;
}
function isWebLink(corpus_id) {
    return corpus_id.toLowerCase().startsWith("web");
}
function openWebUrl(url) {
    window.open(url, "_blank");
}
export function openUrl({ title, corpus_id, identifier, externalUrl, url = "", highlight = [], onSelectionChange, metadata, metadataSorter, disableScrollToHighlight, }) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isWebLink(corpus_id)) {
            openWebUrl(url);
            return;
        }
        try {
            setLoading(true);
            const identifierParams = identifier.type === "document"
                ? { id: identifier.id }
                : { article_id: identifier.id };
            const docContent = yield API.corporaDocument(Object.assign({ corpus_ids: [corpus_id] }, identifierParams));
            if (docContent) {
                const hostname = Url.parse(url).hostname || undefined;
                showDocViewer({
                    title: (_jsx(DocTitle, { title: title, highlight: highlight, hostname: hostname })),
                    url,
                    doc: { value: docContent.text, loading: false },
                    highlight,
                    onSelectionChange,
                    metadata: metadata || docContent.metadata,
                    metadataSorter,
                    externalUrl: externalUrl || docContent.external_url,
                    disableScrollToHighlight,
                    citations: docContent.citations,
                });
            }
            else {
                openWebUrl(url);
            }
        }
        finally {
            setLoading(false);
        }
        function DocTitle({ title = "", highlight, hostname, }) {
            const truncated = limitLength(title, 125);
            const highlights = !highlight
                ? []
                : typeof highlight === "string"
                    ? [highlight]
                    : highlight;
            const highlightSpans = truncated
                ? highlights.map(h => findTextSpans(title, h)).flat()
                : [];
            const titleComp = (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: " " }, { children: truncated ? (_jsx(AnnotatedString, { text: truncated, annotations: (highlightSpans || []).map(s => ({
                            span: s,
                            annotation: "bg-yellow-200 text-black",
                        })), renderAnnotation: ({ text, annotations, annotationIndex }) => {
                            if (annotations.length === 0) {
                                return text;
                            }
                            return _jsxs("span", Object.assign({ className: annotations[0] }, { children: [" ", text, " "] }));
                        } })) : hostname ? (`Evidence at ${hostname}`) : ("Evidence") })) }));
            return truncated === title ? (titleComp) : (_jsx(Tooltip, Object.assign({ content: title }, { children: titleComp })));
        }
    });
}
