import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useEvidenceSearchUtils } from "../../../appState/context/evidence/EvidenceSearchContext";
import { camelify } from "../utils/utils";
import { take } from "lodash";
export function MetadataReference({ evidence, className, }) {
    const { getAssumedEvidenceMetadata } = useEvidenceSearchUtils();
    const metadata = getAssumedEvidenceMetadata(evidence.id);
    const hasAuthors = metadata.authors && metadata.authors.length > 0;
    const hasSource = metadata.publicationSource;
    return (_jsx(DocumentReference, { className: className, authors: metadata.authors, source: metadata.publicationSource, publicationDate: metadata.publicationDate }));
}
export function DocumentReference({ authors, authorCount = 1, source, publicationDate, className, }) {
    if (!authors && !source && !publicationDate) {
        return null;
    }
    return (_jsxs("span", Object.assign({ className: className }, { children: [authors && authors.length > 0 ? (_jsx("span", { children: `${take(authors, authorCount).map(author => camelify(author))}${authors.length > authorCount ? `, et. al.` : ""}` })) : undefined, source && (_jsxs("span", { children: [" ", "in ", _jsx("i", { children: source })] })), publicationDate && (_jsxs("span", { children: [(authors && authors.length > 0) || source ? ", " : "", publicationDate.split("-")[0]] }))] })));
}
