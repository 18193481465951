import { getDocumentUrl, openUrl } from "../../api/utils/url";
import { highlightWordsOutsideOfParse } from "../../appState/atomic/evidence/utils/highlightWordsOutsideOfParse";
import { sortMetadata } from "../../appState/atomic/queryState/semanticSearch/sortMetadata";
import { useShowSelectionMenu } from "../contextMenu/showSelectionMenu";
export function useUrlOpener({ suggestEvidence, currentContextTitle, findings, structuredQuery, usingHierarchyNav, source, }) {
    const showSelectionMenu = useShowSelectionMenu();
    return (evidence, instance, highlights, disableScrollToHighlight) => {
        openUrl({
            url: getDocumentUrl(instance.document_id),
            corpus_id: instance.corpus_id,
            identifier: { type: "document", id: instance.document_id },
            highlight: highlights || [
                ...highlightWordsOutsideOfParse(evidence),
                evidence.text,
            ],
            title: instance.title,
            onSelectionChange: ({ rect, text, docText }) => showSelectionMenu(rect, {
                text,
                suggestEvidence,
                findings,
                evidence,
                instance,
                context: docText,
                currentContextTitle,
                structuredQuery,
                usingHierarchyNav,
                zIndex: 2000,
            }),
            metadata: instance.metadata,
            metadataSorter: sortMetadata(source),
            externalUrl: instance.external_url,
            disableScrollToHighlight,
        });
    };
}
