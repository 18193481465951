var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { highlightWordsOutsideOfParse } from "../../appState/atomic/evidence/utils/highlightWordsOutsideOfParse";
import { InfoRow } from "./InfoRow";
export function EvidenceHeader({ evidence, highlight = evidence.text, urlOpener, onSelectionChangeInDocView, suffix, metadataSorter, }) {
    const highlightWords = highlightWordsOutsideOfParse(evidence);
    return (_jsx(InfoRow, { highlight: [
            ...highlightWords,
            ...(!highlight
                ? []
                : Array.isArray(highlight)
                    ? highlight
                    : [highlight]),
        ], metadataSorter: metadataSorter, sources: evidence.instances, urlOpener: urlOpener &&
            (({ url }) => __awaiter(this, void 0, void 0, function* () {
                return urlOpener(evidence, evidence.instances.find(i => {
                    return i.document_id === url;
                }));
            })), onSelectionChangeInDocView: onSelectionChangeInDocView, suffix: suffix }));
}
