import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useMultihopStrategy } from "../../api/types/MultihopRelationship";
import { Feature, useIsFeatureSupported, } from "../../appState/atomic/featureFlags/features";
import { useDisplayState } from "../../appState/atomic/session/parts/displayState";
import { useUserConfig } from "../../appState/atomic/session/parts/userConfig";
import { Graph } from "../common/icons/Icons";
import ConstraintFlow from "./ConstraintFlow";
export function useActivateModelGraph() {
    const [{ showModelGraph, showTrends }, displayUpdater] = useDisplayState();
    return () => displayUpdater({
        showModelGraph: !showModelGraph,
        showTrends: !showModelGraph ? false : showTrends,
    });
}
export function useDMParamsDiagramAddOn({ argsInfo, index, }) {
    const [{ showModelGraph, showTrends }, displayUpdater] = useDisplayState();
    const [{ queryGraph, modelBuilderAvailable }] = useUserConfig();
    const supportsGraph = useIsFeatureSupported(Feature.QUERY_GRAPH);
    const { isMultihop } = useMultihopStrategy();
    const activateModelGraph = useActivateModelGraph();
    React.useEffect(() => {
        showTrends && displayUpdater({ showModelGraph: false });
    }, [showTrends, displayUpdater]);
    const show = (supportsGraph && isMultihop) ||
        (supportsGraph && showModelGraph && queryGraph && !modelBuilderAvailable);
    return !supportsGraph
        ? undefined
        : {
            name: "modelGraph",
            index,
            selector(key) {
                if (!queryGraph || modelBuilderAvailable)
                    return null;
                return {
                    key,
                    icon: _jsx(Graph, {}),
                    tooltip: "View Graph",
                    selected: show,
                    disabled: isMultihop,
                    onSelect: activateModelGraph,
                };
            },
            widgetSize: { min: 100, max: 2000 },
            widget() {
                return show ? _jsx(ConstraintFlow, {}) : null;
            },
        };
}
