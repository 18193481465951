import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import chroma from "chroma-js";
import { isEqual, sortBy, uniq, uniqBy } from "lodash";
import React, { useMemo } from "react";
import { useTheme } from "../../../appState/context/theme/ThemeContext";
import { classes } from "../../../appState/context/theme/classes";
import { useMemoIfEqual } from "../../../utils/lifecycle/useMemoIfEqual";
import { Tooltip } from "../popups/Tooltip";
import { AnnotatedString } from "./AnnotatedString";
import { Tooltip as AntTooltip } from "antd";
import { DocumentReference } from "../../results/evidenceDisplay/RenderNiceMetadata";
import { BorderlessButton } from "../buttons/BorderlessButton";
import { ShareFromSquare } from "../icons/Icons";
import { openUrl } from "../../../api/utils/url";
function MarkDown({ text, match, matchRenderer, noMatchRenderer, className, style, }) {
    return (_jsx("span", Object.assign({ className: className, style: style }, { children: match
            ? text
                .split(match)
                .map((part, idx) => idx % 2 === 0 ? (noMatchRenderer ? (noMatchRenderer(part, idx)) : (_jsx("span", { children: part }, idx))) : (matchRenderer(part, idx)))
            : noMatchRenderer
                ? noMatchRenderer(text, 0)
                : text })));
}
export function DefaultMarkDown({ text, separator, prefix, markDownClass = "bg-gray-300", markDownStyle, className, style, }) {
    const regExp = separator
        ? new RegExp(`(${prefix ? "\b" : ""}${regEscape(separator)})`, "ig")
        : undefined;
    return (_jsx(MarkDown, { text: text, className: className, style: style, match: regExp, matchRenderer: (text, idx) => (_jsx("span", Object.assign({ style: Object.assign({ overflow: "hidden" }, markDownStyle), className: markDownClass }, { children: text }), idx)) }));
}
export function EvidenceMarkDown({ evidence, text, className, annotationProvider, }) {
    const theme = useTheme();
    return useMemo(() => {
        const additionalSpans = evidence.argument_spans.reduce((acc, s) => {
            acc.push({
                span: s.span,
                type: "argument",
                payload: s.argument,
            });
            acc.push(...s.concept_spans.map(c => ({
                span: c.span,
                type: "concept",
                payload: c,
            })));
            return acc;
        }, []);
        const citationSpans = evidence.instances.flatMap(i => i.citation_references
            ? i.citation_references.map(r => ({
                type: "citation",
                span: r.span,
                annotation: {
                    type: "citation",
                    value: {
                        citation: r,
                        sourceInstance: i,
                    },
                },
            }))
            : []);
        const highlightSpans = evidence.highlight_spans.reduce((acc, h) => {
            if (h.span) {
                acc.push({
                    span: h.span,
                    annotation: {
                        type: "highlight",
                        value: {
                            span: h.span,
                            match: h,
                            additionalSpans: additionalSpans.filter(aSpan => isEqual(aSpan.span, h.span)),
                        },
                    },
                });
            }
            return acc;
        }, []);
        const getHighlights = (spans) => {
            const anns = annotationProvider ? annotationProvider(spans) : undefined;
            const empty = {
                style: {},
                supStyle: {},
                concepts: [],
                relations: [],
                args: [],
            };
            const highlights = anns
                ? anns.reduce((acc, h) => {
                    if (h.type === "keyword_match") {
                        acc.style.backgroundColor = chroma("gray").alpha(0.25).css();
                    }
                    else if (h.type === "text_match") {
                        acc.style.fontWeight = 700;
                    }
                    else if (h.type === "concept") {
                        acc.style.color = theme.colors.text_highlight;
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = theme.colors.text_highlight;
                        acc.supStyle.fontWeight = 700;
                        acc.concepts.push(h.value);
                    }
                    else if (h.type === "argument") {
                        acc.style.color = theme.colors.text_highlight;
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = theme.colors.text_highlight;
                        acc.supStyle.fontWeight = 700;
                        acc.args.push(h.value);
                    }
                    else if (h.type === "relation") {
                        acc.style.color = theme.colors.text_highlight;
                        acc.style.fontWeight = 500;
                        acc.supStyle.color = theme.colors.text_highlight;
                        acc.supStyle.fontWeight = 700;
                        acc.relations.push(h.value);
                    }
                    return acc;
                }, empty)
                : empty;
            return highlights;
        };
        const spans = [...highlightSpans, ...citationSpans];
        return (_jsx("span", Object.assign({ className: classes("MarkDown", className) }, { children: _jsx(AnnotatedString, { text: text, annotations: spans, renderAnnotation: ({ text, annotations, neighboringAnnotations }) => {
                    const highlightValues = annotations
                        .filter(a => a.type === "highlight")
                        .map(a => a.value);
                    const afterHighlightValues = neighboringAnnotations.after
                        .filter(a => a.type === "highlight")
                        .map(a => a.value);
                    const citationValues = annotations
                        .filter(a => a.type === "citation")
                        .map(a => a.value);
                    const highlights = getHighlights(highlightValues);
                    const nextHighlights = getHighlights(afterHighlightValues);
                    const superscripts = uniq([
                        sortBy(highlights.concepts, "ordering")
                            .map(c => c.key)
                            .filter(c => !nextHighlights.concepts.map(c => c.key).includes(c)),
                        sortBy(highlights.relations, "key")
                            .map(r => r.key)
                            .filter(r => !nextHighlights.relations.map(r => r.key).includes(r)),
                        sortBy(highlights.args, "key")
                            .map(a => a.key)
                            .filter(a => !nextHighlights.args.map(a => a.key).includes(a)),
                    ].flat());
                    const tooltips = [
                        ...uniqBy([highlights.relations, highlights.args].flat(), "value"),
                        ...uniqBy(highlights.concepts, "key"),
                    ];
                    let citationText = "";
                    if (citationValues.length > 0) {
                        const hasAuthors = citationValues[0].citation.authors.length > 0;
                        const useEtAl = citationValues[0].citation.authors.length > 1;
                        const hasDate = isDate(citationValues[0].citation.publication_date);
                        citationText = hasAuthors
                            ? `${citationValues[0].citation.authors[0].trim()}${useEtAl ? " et al." : ""}`
                            : hasDate
                                ? citationValues[0].citation.publication_date.split("-")[0]
                                : text;
                    }
                    const isCitation = citationText.length > 0;
                    const body = (_jsxs(_Fragment, { children: [isCitation ? (_jsx("span", Object.assign({ className: "whitespace-pre-wrap", style: {
                                    color: theme.colors.text_highlight,
                                } }, { children: citationText }))) : (_jsx("span", Object.assign({ className: "whitespace-pre-wrap", style: highlights.style }, { children: text }))), !isCitation && superscripts.length > 0 ? (_jsx("sup", Object.assign({ className: "select-none clearselect", style: highlights.supStyle }, { children: superscripts.map(s => `[${s}]`).join("") }))) : null] }));
                    return isCitation ? (_jsx(CitationTooltip, Object.assign({ sourceInstance: citationValues[0].sourceInstance, citation: citationValues[0].citation }, { children: body }))) : tooltips.length > 0 ? (_jsx(AnnotationTooltip, Object.assign({ tooltips: tooltips }, { children: body }))) : (body);
                } }) })));
    }, [useMemoIfEqual(evidence), annotationProvider, text, className, theme]);
}
function CitationTooltip({ citation, sourceInstance, children, }) {
    return (_jsx(AntTooltip, Object.assign({ color: "white", placement: "bottom", overlayStyle: {
            maxWidth: "35rem",
        }, title: _jsxs("div", Object.assign({ className: "flex flex-col items-start space-y-2 text-base text-black" }, { children: [citation.title && (_jsx("span", Object.assign({ className: "font-bold" }, { children: citation.title }))), _jsx(DocumentReference, { className: "text-sm text-gray-600", authors: citation.authors, source: citation.publication_name, publicationDate: citation.publication_date }), citation.article_ids.length > 0 && (_jsxs(BorderlessButton, Object.assign({ className: "hover:text-accent-500 text-gray-600 flex items-center space-x-1", onClick: () => openUrl({
                        title: citation.title,
                        corpus_id: sourceInstance.corpus_id,
                        identifier: {
                            type: "article",
                            id: citation.article_ids[0],
                        },
                        externalUrl: citation.external_url,
                    }) }, { children: [_jsx("span", { children: "Open document" }), _jsx(ShareFromSquare, {})] }))), citation.external_url && (_jsxs(BorderlessButton, Object.assign({ onClick: () => window.open(`${citation.external_url}`, "_blank"), className: "hover:text-accent-500 text-gray-600 flex items-center space-x-1" }, { children: [_jsx("span", Object.assign({ className: "" }, { children: "Open source" })), _jsx(ShareFromSquare, {})] })))] })) }, { children: children })));
}
function AnnotationTooltip({ tooltips, children, }) {
    const sorter = (s) => sortBy(sortBy(s, "key"), "ordering");
    return (_jsx(Tooltip, Object.assign({ wrapperClassname: "inline-block", content: _jsx("div", { children: sorter(tooltips).map((t, idx) => (_jsxs("div", Object.assign({ className: "text-sm" }, { children: [_jsxs("b", { children: [t.context, " (", t.key, ")"] }), ": ", t.value] }), idx))) }) }, { children: children })));
}
function regEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
// function to check if string is a date with a leading year
function isDate(text) {
    return /^\d{4}-\d{1,2}-\d{1,2}/.test(text);
}
